import { DefaultState, TableDataResponse } from 'features/types';
import { Tab, TableTabFilters,  Paging } from 'api/types';
import { OrganizationMainFields } from 'features/organizations/types';
import { ProgramBaseTableView } from 'components/ProgramBaseTableComponent/types';
import { GetProgramsFilters } from 'features/programs-filters';

export enum JobSeekerProgramMembership {
  All = 'All',
  Joined = 'Joined',
}


export type JobSeekerDiscoverProgramTableView = ProgramBaseTableView & {
  organizationId: string;
  organizationName: string;
  logo?: string;
  isMemberInProgram: boolean;
};

export type GetJobSeekerProgramConnectionsTable = {
  filters: GetProgramsFilters;
  paging: Paging;
};

export type ExploreJobSeekerProgramsState = DefaultState & {
  requestData: {
    paging: Paging;
    filters: TableTabFilters;
  },
  data: {
    tableDataIsLoading: boolean;
    tableData: TableDataResponse<JobSeekerDiscoverProgramTableView>,
    selectedOrganization: OrganizationMainFields | null;
    tabsIsLoading: boolean;
    tabs: Tab[];
  },
};

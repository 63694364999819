import { Redirect } from 'react-router-dom';
import { ROUTES } from 'app/routes';
import { User } from 'features/auth/types';
import { CompanyStatuses } from 'features/types';

type Props = {
  user: User | null;
}

const UserRedirectToHomePage = ({ user }: Props) => {
  if (user?.activeAccountStatus === CompanyStatuses.OrganizationCompleted) {
    return <Redirect to={ROUTES.OPPORTUNITIES} />;
  } else if (user?.activeAccountStatus === CompanyStatuses.EmployerCompleted) {
    return <Redirect to={ROUTES.JOB_POSTS} />;
  } else {
    return <Redirect to={ROUTES.ACCOUNT_SETUP} />
  }
};

export default UserRedirectToHomePage;